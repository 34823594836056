import React from "react"
import SEO from "../components/Seo"
import Layout from "../components/Layout"
import Section from "../components/Section"
import { Container, Row, Column } from "../components/Grid"
import Resource from "../components/Resource"

const StudentsPage = () => (
  <Layout>
    <SEO title="Students" />
    {/***************  HERO ***************/}
    <Section className="hero">
      <Container>
        <Row>
          <Column span={12}>
            <h1>Students</h1>
            <h3 className="hero__subhead">
              Stay informed on the health risks of vaping
            </h3>
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  INTRO ***************/}
    <Section className="lightgray intro">
      <Container>
        <Row>
          <Column span={10}>
            <h2 className="serif">Self-Paced Modules</h2>
            <p>
              The choices you make every day have a big impact on your overall
              health. What to eat, when to sleep, how much to exercise. But
              making healthy decisions requires an understanding of the real
              truth and consequences of an action. There is so much
              misinformation out there on vaping – sometimes it’s hard to know
              what’s real. Check out these modules to get the facts and decide
              for yourself.
            </p>
          </Column>
        </Row>
      </Container>

      {/* <Row>
          <Column span={12}>
            <div className="anchor__navbar">
              <ul>
                <li className="anchor__navbar-label">Grade Band:</li>
                <li className="anchor__navbar-item">
                  <a href="#elementarySchool">Elementary School</a>
                </li>
                <li className="anchor__navbar-item">
                  <a href="#middleSchool">Middle School</a>
                </li>
                <li className="anchor__navbar-item">
                  <a href="#highSchool">High School</a>
                </li>
              </ul>
            </div>
          </Column>
        </Row> */}

      <div id="elementarySchool"></div>
      <Resource
        label="Self-Paced Module"
        image="video-thmb-e-disease.jpg"
        subjects={["Science", "Health"]}
        title="Don’t Let Vaping Cloud the Facts"
        grade="Elementary School"
        duration="Duration: 10-15 min."
        description={
          <p>
            Do you know the truth and consequences of vaping and e-cigarette
            use? Let’s dive in and explore!
          </p>
        }
        actions={[
          {
            label: "Get Started",
            url: "/cvs-module/",
            type: "external",
          },
        ]}
        horizontal
      />

      <Resource
        label="Self-Paced Module"
        image="risks-of-vaping.jpg"
        subjects={["Science", "Health"]}
        title="Vaping Risks Revealed"
        grade="Middle School"
        duration="Duration: 15-20 min."
        description={
          <p>
            Some people claim that using e-cigarettes is safer than vaping. But
            is it actually safe? Explore this module to find out.
          </p>
        }
        actions={[
          {
            label: "Get Started",
            url: "/vaping-risks-revealed/",
            type: "external",
          },
        ]}
        horizontal
      />
      <Resource
        label="Self-Paced Module"
        image="Living-Vape-free.png"
        subjects={["Science", "Health"]}
        title="Empowering Yourself and Others to Be Vape Free"
        grade="High School"
        duration="Duration: 20-25 min."
        description={
          <p>
            In this self-paced module, students will learn how to empower
            themselves and others to stay vape free. They will discover how to
            be informed consumers and critical observers while using social
            media.
          </p>
        }
        actions={[
          {
            label: "Get Started",
            url: "/empower-yourself/",
            type: "external",
          },
        ]}
        horizontal
      />

      {/* 
          <div id="middleSchool"></div>
          <Resource
            label="Self-Paced Module"
            image="img-spm-placeholder-TEMP.jpg"
            subjects={["Subject Type"]}
            title="Main Title Here"
            grade="Elementary"
            duration="Duration or Length"
            description={
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                tempued puru ultricies dolor tincidunt. Curabitur rutrum a nuc
                tet thjdunc. Nulla tempued puru ultricies dolor tincidunt.
                Curabitur rutrum a nuc tet thjdunc.
              </p>
            }
            actions={[
              {
                label: "Get Started",
                path: "#",
                type: "download",
              },
            ]}
            horizontal
          />
         */}
      {/* 
          <div id="highSchool"></div>
          <Resource
            label="Self-Paced Module"
            image="img-spm-placeholder-TEMP.jpg"
            subjects={["Subject Type"]}
            title="Main Title Here"
            grade="Elementary"
            duration="Duration or Length"
            description={
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                tempued puru ultricies dolor tincidunt. Curabitur rutrum a nuc
                tet thjdunc. Nulla tempued puru ultricies dolor tincidunt.
                Curabitur rutrum a nuc tet thjdunc.
              </p>
            }
            actions={[
              {
                label: "Get Started",
                path: "#",
                type: "download",
              },
            ]}
            horizontal
          />
         */}
    </Section>
  </Layout>
)
export default StudentsPage
